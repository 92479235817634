
































import Vue from 'vue';
import Component from 'vue-class-component';
import CommonIcon from 'common-modules/src/components/CommonIcon.vue';
import { mapGetters } from 'vuex';
import JwlButton from '@/components/JwlButton.vue';
import SisSortableTable from '../helper/sisSortableTable.vue';
import TableHead from '../helper/TableHead';

const CommonError = () => import('common-modules/src/components/CommonError.vue');

@Component({
  components: {
    JwlButton,
    CommonError,
    CommonIcon,
    SisSortableTable,
  },
  computed: mapGetters([
    'facilitators',
  ]),
})
export default class JwlFacilitator extends Vue {
  facilitators: any[] = [];
  searchTerm = '';
  loading = true;
  error = null;
  tableHeadDefinition = [
    new TableHead('general.lmsId', 'lmsId'),
    new TableHead('general.name', 'name'),
    new TableHead('general.isGlobal', 'isGlobal', true, 'boolean'),
    new TableHead(null, null, false, 'link', 'facilitatorEdit', 'facilitator'),
  ];

  get filteredFacilitators (): any[] {
    return this.facilitators.filter((facilitator) => {
      const filterLmsId = facilitator.lmsId.toLowerCase().includes(this.searchTerm.toLowerCase());
      const filterName = facilitator.name.toLowerCase().includes(this.searchTerm.toLowerCase());
      return filterLmsId || filterName;
    });
  }

  mounted (): void {
    this.$store.dispatch('getData', 'facilitator/list')
      .then((data) => {
        this.facilitators = data;
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        this.error = e;
      });
  }
}
